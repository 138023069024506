<template>
      
  <div class="all">
    <div class="banner">
      <img :src="banners" alt="" />
    </div>
    <div class="numberDetails_details">
      <!-- <aside class="action">
        首页 &nbsp;
        <img src="../assets/img/InforCenter/juxing14.png" alt="" />
        &nbsp;资讯中心 &nbsp;<img
          src="../assets/img/InforCenter/juxing14.png"
          alt=""
        />
        &nbsp;通知公告
      </aside> -->
    <mainBox></mainBox>
      
      <div class="numberDetails_detailsImg">
        <img :src="NumberList.img" alt="" />
      </div>
      <div class="numberDetails_detailsTitle">{{NumberList.name}}</div>
      <div class="numberDetails_detailsText">
        <p>
            <!-- {{NumberList.describe}} -->
          <!-- 粉彩“二甲传胪”图鼻烟壶，清道光，高5.8厘米，口径1.8厘米，足横2.8厘米，足纵1.7厘米。  <br>
          鼻烟壶小口、椭圆形扁圆腹、椭圆形圈足。腹部两面均以粉彩描绘两只螃蟹和芦苇、水藻。外底自右向左<br>
          署矾红彩篆体“道光年制”四字横排款。 -->
        </p>
        　<div style="textAlign:center" v-html="NumberList.keys"></div>
        <!-- <p>
          　　乍看这件鼻烟壶上的图案，似为常见的池塘小景，其实它是一种人们喜闻乐见的吉祥图案——“二甲传胪”，<br>
          亦称“黄甲传胪”。我国古代科举甲科及第者，其名附卷末，用黄纸书写，故曰黄甲。清代科举考试分为三级<br>
          ，即乡试、会试、殿试。殿试亦称廷试，由皇帝亲自主持。殿试名次分为三甲，一甲共三名，依次为“状元”、“<br>
          榜眼”、“探花”，赐进士及第。二甲若干名，赐进士出身。三甲若干名，赐同进士出身。明代科举称第二、三甲<br>
          中的第一名为“传胪”，清代则专称二甲第一名为“传胪”。<br>
          另外，“胪”的本意为陈述、陈列，我国古代以上传语<br>
          告下为胪，故“传胪”亦指科举时代殿试揭晓唱名的一种仪式。殿试公布名次之日，皇帝亲御太和殿，传胪官宣<br>
          布第一、二、三甲名次，由阁门承接，传于阶下，卫士齐声传名高呼，谓之传胪。若再加以引申，“传胪”泛指<br>
          科举及第。
        </p>
        <p>
          由于螃蟹有坚硬之甲壳，而且《事物异名录·水族蟹》：曰：“蟹之大者曰蝤蛑，名黄甲”。芦苇之“芦”谐<br>
          “胪”音，因此，古人常画两只螃蟹配以芦苇，寓意“二甲传胪”或“黄甲传胪”，藉此祝人官运亨通、前程似锦。
        </p> -->
            
            <Share :titles="NumberList.name" :images="NumberList.img"></Share>
      </div>
    </div>
    <div class="numberDetails_xiangguan" v-if="likes.length !== 0">
            <img src="../assets/museumDetails/titou.png" class="title" alt=""> 
            <img class="NubDetails_titleImg" src="../assets/museumDetails/recommend.png" alt="">
    </div>
    <div class="numberDetails_xiangguanList" v-if="likes.length !== 0">
      <div class="CateChildren" v-for="(item,key) in likes" :key="key" :class="key == 0 ?  '' : 'marl'" @click="number_skip(item.id)">
        <img :src="item.img" alt="" />
        <div class="cateBorder"></div>
        <div class="title">
          {{item.name}}
        </div>
        <div class="details" v-html="item.des"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import Footer from '../components/footer'
import { fetch } from '../api/https';
import Share from '../components/share.vue';
import mainBox from "@/components/main.vue";

export default {
  data() {
    return {
        NumberList:{},
        likes:[],
        banners:''
    };
  },
  components:{
    Footer,
    Share,
    mainBox
  },
  created() {
  },
  mounted() {
      this.axio()

  },
  watch: {
    $route(to, from) {
      if (this.$route.query.id) {
      }
    }
},
  methods: {
    haveCode(){
            var qrcode = new QRCode("qrcode", {
            text: window.location.href,
            width: 100,
            height: 100,
            colorDark : "#000000",
            colorLight : "#ffffff",
            correctLevel : QRCode.CorrectLevel.H
            });
        },
        number_skip(id){
            this.$router.push(`/digitDetails?id=${id}`)
            this.$router.go(0)
        },
      axio(){
            // window.scrollTo(0)
          fetch('/getmenu')
            .then(res => {
              this.banners = res.data[4].child[0].menu_img;
              document.title = res.data[4].child[0].menu_seo_title
            })
            .catch(err => {});
          fetch(`/collect/detail/${this.$route.query.id}`).then((res) => {
              this.NumberList = res.data
              document.title = this.NumberList.name
          })
          fetch(`collect/other`,{id:this.$route.query.id}).then((res) => {
              this.likes = res.data
          })
      }
  },
  
};
</script>

<style scoped>
#qrcode{
  width: 100px;
  height: 100px;
  padding: 6px;
  background-color: white;
}
#qrcode img{
  width: 100px;
  height: 100px;
  padding: 6px;
  background-color: white;
  display: inline-block;
}
.all {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.banner {
  height: 600px;
  width: 100%;
}
.banner img {
  height: 100%;
  width: 100%;
}
.numberDetails_details {
  width: 1398px;
  margin: 0 auto;
  background: #f8f8f8;
  height: auto;
  margin-bottom: 72px;
}
.action {
  display: block;
  font-size: 16px;
  padding: 129px;
  font-weight: 400;
  padding-left: 31px;
  padding-top: 25px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1A1A1A;
}
.numberDetails_detailsImg {
  max-width: 1400px;
  height: auto;
}
.numberDetails_detailsImg img {
  display: block;
  /* width: 532px;
  height: 663px; */
  margin: 0 auto;
  margin-bottom: 85px;
  width: 245px;
  height: 306px;
}
.numberDetails_detailsTitle {
  max-width: 1400px;
  height: auto;
  height: 29px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 540;
  color: #888888;
  padding-bottom: 50px;
  text-align: center;
}
.numberDetails_detailsText {
  padding: 0 117px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #888888;
  line-height: 48px;
  box-sizing: border-box;
}
p{
    text-align: center;
}
.foots{
    
    text-align: right;
    max-width: 1400px;
    height: 114px;

    font-size: 20px;
    line-height: 30px;
    margin: 0 auto;
        padding-top: 47px;
    box-sizing: border-box;
    padding-bottom: 37px;
    padding-right: 122px;
}
.foots img{
    height: 30px;
    width: 30px;
    margin:0 10px ;
    float: right;
    /* margin-top: -25px; */
    /* margin-top: 75px; */
}
.numberDetails_xiangguan{
    margin-top: 72px;
    max-width: 1400px;
    margin: 0 auto;
    height: 42px;   
    padding-bottom: 37px;
}
.numberDetails_xiangguan .title{
        height: 21px;
        width: 26px;
        margin-top: 9px;
        margin-right: 24px;
        float: left;
}
.numberDetails_xiangguanList{
    width: 1400px;
  height: 392px;
  padding-bottom: 58px;
  margin: 0 auto;

}
.CateChildren {
  width: 245px;
  height: 392px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  float: left;
  position: relative;
}
.marl{
  margin-left: 41px;

}
.CateChildren img {
  width: 245px;
  height: 306px;
}
.cateBorder {
  width: 233px;
  height: 292px;
  border: 1px solid #bd955d;
  position: absolute;
  left: 6px;
  top: 6px;
}
.fen {
  width: 0px;
  height: 10px;
  border: 1px solid #bd955d;
  display: inline-block;
  margin-top: 3px;
}
.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 14px;
}
.details {
  width: 206px;
  height: 17px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 21px;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.NubDetails_titleImg{
    width: 176px;
    height: 42px;
    float: left;
}
@media screen and (max-width: 1440px){
  .numberDetails_details{
    width: 1266px;
  }
  .numberDetails_xiangguan{
    max-width: 1266px;
  }
  .numberDetails_xiangguanList{
    width: 1266px;
  }
  .marl{
    margin-left: 10px;
  }
}
</style>
